:root {
    --color-accent: #da9109;
    --color-headings: #001524;
    --color-body: #d7e5ca;
    --color-body-darker: #79ac78;
    --color-border: #faf2d3;
    --border-radius: 30px;
    --color-text: #676a67;
    --max-width: 1920px;
    color-scheme: light dark;
}

/* samsung internet ?*/

@media (prefers-color-scheme: light) {
    .App {
        background-color: var(--color-body);
    }
}

@media (prefers-color-scheme: dark) {
    body {
        background-color: var(--color-body);
        color: var(--color-text);
    }

    .App {
        background-color: var(--color-body);
    }
}

body,
p,
li,
a {
    font-family: "Lora", sans-serif;
    font-weight: 400;
    font-size: calc(6px + 2vmin);
    color: var(--color-text);
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lora", serif;
    font-weight: 400;
    margin-bottom: 0px;
    font-size: calc(16px + 2vmin);
    color: var(--color-headings);
}

body {
    scrollbar-width: thin;
    background-color: var(--color-body);
    overflow: hidden;
}

body::-webkit-scrollbar-track {
    background: var(--color-body);
}

body::-webkit-scrollbar-thumb {
    background: rgba(43, 36, 36, 0.665);
    border-radius: 200px;
}

body::-webkit-scrollbar {
    width: 6px;
}

.no-scroll {
    overflow: hidden;
}

.App {
    text-align: center;
    background-color: var(--color-body);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("./images/logo/bg-paper-green.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.App-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 2vmin);
    color: var(--color-headings);
    max-width: var(--max-width);
    margin: 0;
    padding: 0;
    transition: ease-in-out 0.5s;
}

.varikaspoika {
    color: var(--color-accent);
}

.picture {
    height: 20vmin;
    max-width: fit-content;
    margin: 8px;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
}

.pic1 {
    height: 24vmin;
    max-width: fit-content;
    margin: 8px;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    z-index: 2;
    transform: rotate(1deg);
}

.pic2 {
    z-index: 0;
    transform: rotate(-0.5deg);
    max-width: max-content;
    max-height: max-content;
    cursor: pointer;
}

.pic3 {
    height: 30vmin;
    max-width: max-content;
    position: relative;
    top: 4vh;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    transform: rotate(1.3deg);
}
.pic4 {
    transform: rotate(-0.2deg);
}

.pic5 {
    height: 26vmin;
    max-width: max-content;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    transform: rotate(-2deg);
}
.pic6 {
    height: 24vmin;
    max-width: max-content;
    position: relative;
    right: 4vh;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    transform: rotate(2deg);
}
.pic7 {
    height: 24vmin;
    max-width: max-content;
    position: relative;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    transform: rotate(1deg);
}
.pic8 {
    height: 28vmin;
    max-width: max-content;
    position: relative;
    left: 4vh;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    transform: rotate(1deg);
}

.pic9 {
    height: 28vmin;
    position: relative;
    top: 5vh;
    max-width: max-content;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    left: 2.5vh;
    cursor: pointer;
    transform: rotate(-1deg);
    z-index: 2;
}
.pic10 {
    height: 24vmin;
    position: relative;
    max-width: max-content;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    right: 1vh;
    cursor: pointer;
    transform: rotate(-2deg);
}

.pic11 {
    height: 24vmin;
    max-width: max-content;
    max-height: max-content;
    position: relative;
    left: 15vh;
    transition: ease-in-out 0.3s;
    box-shadow: 3px 3px 20px 3px #999;
    cursor: pointer;
    transform: rotate(-1deg);
}

@media screen and (max-width: 1440px) {
    .pic6 {
        right: 3.5vh;
    }
}
@media screen and (max-width: 1160px) {
    .pic6 {
        right: 3vh;
    }
}

/* small screens */

@media screen and (max-width: 1024px) and (orientation: portrait) {
    body {
        overflow: scroll;
    }

    .picture,
    .pic1,
    .pic2,
    .pic3,
    .pic4,
    .pic5,
    .pic6,
    .pic7,
    .pic8,
    .pic9,
    .pic10,
    .pic11 {
        width: 90%;
        height: auto;
        position: relative;
        margin-bottom: 20px;
        min-width: 90%;
        transform: rotate(0deg);
    }
    .landscape {
        margin: 0;
        height: auto;
        width: 90%;
        margin-bottom: 20px;
    }

    .pic1 {
        top: 0;
    }

    .pic3 {
        top: 0;
    }

    .pic6 {
        right: 0;
    }

    .pic8 {
        left: 0;
    }
    .pic9 {
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }
    .pic10 {
        right: 0;
    }
    .pic11 {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .pic1:hover,
    .pic2:hover,
    .pic3:hover,
    .pic4:hover,
    .pic5:hover,
    .pic6:hover,
    .pic7:hover,
    .pic8:hover,
    .pic9:hover,
    .pic10:hover,
    .pic11:hover,
    .picture:hover,
    .landscape:hover {
        transform: scale(1);
    }
}

/* Mobile and small screens landscape - Adjusted for iPhone */

@media screen and (max-width: 860px) and (orientation: landscape) {
    .grid--4 > div,
    .grid--3 > div {
        position: relative !important;
    }

    body {
        overflow: scroll;
    }

    .pic1 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 65%;
        height: auto;
        position: relative;
    }

    .pic2 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 85%;
        height: auto;
        position: relative;
    }

    .pic3 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 65%;
        height: auto;
        position: relative;
    }

    .pic4 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 95%;
        height: auto;
        position: relative;
    }

    .pic5 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 65%;
        height: auto;
        position: relative;
    }

    .pic6 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 65%;
        height: auto;
        position: relative;
        right: 30%;
        top: 0;
    }

    .pic7 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 65%;
        height: auto;
        position: relative;
    }

    .pic8 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 95%;
        height: auto;
        position: relative;
        left: 30%;
    }

    .pic9 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 75%;
        height: auto;
        position: relative;
    }

    .pic10 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 65%;
        height: auto;
        position: relative;
    }

    .pic11 {
        transition: ease-in-out 0.3s;
        box-shadow: 3px 3px 20px 3px #999;
        cursor: pointer;
        margin: 1%;
        max-width: 90%;
        height: auto;
        position: relative;
    }

    .grid--4,
    .grid--3 {
        width: 60% !important;
        margin: 0 auto;
    }

    /* Logo Adjustments */

    .merinLogoImgContainer {
        height: 18vmin;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: fadeIn 1s ease-out forwards;
    }
}

/* BIG screens ;) */
@media screen and (min-width: 1280px) {
    /* oli 1440px */
    .pic1 {
        height: 30vh;
        position: relative;
        left: 4vh;
    }
    .pic2 {
        height: 28vh;
    }
    .pic3 {
        height: 34vh;
        right: 1vh;
    }
    .pic4 {
        height: 28vh;
        position: relative;
        right: 1vh;
    }
    .pic5 {
        height: 28vh;
        position: relative;
        left: 2vh;
    }
    .pic6 {
        height: 30vh;
    }
    .pic7 {
        height: 28vh;
        right: 4vh;
        bottom: 2vh;
    }
    .pic8 {
        position: relative;
        left: 9vh;
        bottom: 2vh;
    }
    .pic9 {
        height: 32vh;
        top: 7vh;
        left: 2vh;
    }
    .pic10 {
        height: 30vh;
        position: relative;
        bottom: 4vh;
        right: 3vh;
    }

    .pic11 {
        height: 25vh;
        bottom: 1vh;
        right: 3vh;
    }
}

.title.hidden {
    visibility: hidden;
    transition: visibility 0.5s linear;
}

.title {
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.title-visible {
    opacity: 1;
}

.pic1:hover,
.pic2:hover,
.pic3:hover,
.pic4:hover,
.pic5:hover,
.pic6:hover,
.pic7:hover,
.pic8:hover,
.pic9:hover,
.pic10:hover,
.pic11:hover,
.picture:hover {
    transform: scale(1.01);
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: fit-content;
    top: 1vh;
    padding: 0px;
}
.smaller-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 1.5vh;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: var(--max-width);
}

/* GRID */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.grid--4,
.grid--3 {
    display: grid;
    width: 100%;
    align-items: center;
    justify-items: center;
    animation: fadeIn 1s ease-out forwards;
}

.grid--4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid--3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid--4 > div,
.grid--3 > div {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
    .grid--3,
    .grid--4 {
        grid-template-columns: 1fr;
    }
}

/* MODAL STYLING */

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1); /* (0, 0, 0, 0.85); */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* auto */
    z-index: 5;
    animation: fadeIn 0.5s ease-in-out;
}
.modal-content {
    background: transparent;
    position: relative;
    max-width: 70vw;
    max-height: 95vh;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
    padding-top: 5px;

    /* Firefox scrollbarin style */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.5) transparent;
}

.modal-content img {
    width: 100%;
    object-fit: contain;
    display: block;
    margin-bottom: 10px;
}
.modal-error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-border);
    font-size: 1.5em;
    text-align: center;
}

/* Adjustments for smaller screens and higher zoom levels */
@media screen and (max-width: 1024px) {
    .modal-content img {
        max-width: 90%;
        max-height: 70vh;
        width: 100%;
    }
    .kuvalista {
        flex-direction: column;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}

/* Further adjustments for even smaller screens or higher zoom levels */
@media screen and (max-width: 768px) {
    .modal-content img {
        max-width: 80%;
        max-height: 60vh;
    }
    .kuvalista {
        flex-direction: column;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
}

.close-button {
    position: absolute;
    left: 10px;
    top: 10px;
    background: transparent;
    font-size: 30px;
    border: none;
    cursor: pointer;
    color: grey;
    z-index: 200;
}
.imageClose-button {
    position: absolute;
    left: 10px;
    top: 10px;
    background: transparent;
    font-size: 30px;
    border: none;
    cursor: pointer;
    color: grey;
    z-index: 220;
}
.kuvalista {
    width: 100%;
    column-count: 3;
    column-gap: 10px;
}

.ModaalinNimi {
    position: absolute;
    transform: rotate(-90deg);
    min-width: 90%;
    top: 48%;
    left: -35%;
    animation: fadeIn 2s ease-in-out;
}

.modal-content::-webkit-scrollbar-track {
    background: transparent;
}

.modal-content::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.modal-content::-webkit-scrollbar {
    width: 8px;
}

/* LOGO STYLING */

.merinLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fdf4da;
    width: 100%;
    height: 100%;
    box-shadow: 1px 1px 20px 1px #999;
    border-radius: 2px;
    animation: fadeIn 1s ease-out forwards;
}
.merinLogoImgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 1s ease-out forwards;
}
.merinLogoImg {
    height: 16vmin;
    max-width: fit-content;
    padding: 16px;
    animation: fadeIn 1s ease-out forwards;
}
.merinLogoText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 30%;
    padding-bottom: 6px;
    animation: fadeIn 1s ease-out forwards 100ms;
}
.logoTextNimi {
    width: 100%;
    height: 100%;
    font-size: calc(8px + 1.5vmin);
    font-weight: bold;
    margin-top: 0px;
    animation: fadeIn 1.5s ease-out forwards;
}
.logoTextEmail {
    width: 100%;
    height: 100%;
    font-size: calc(2px + 1.5vmin);
    animation: fadeIn 2s ease-out forwards;
}
.logo {
    width: 20vmin;
    position: absolute;
    z-index: 3;
}

/* this did not work on the max-width: 1024px */
.logoTextNimi,
.logoTextEmail {
    text-decoration: none;
}
.hamburger-icon {
    display: none;
}

/* Mobile portrait screen */

@media screen and (max-width: 1024px) and (orientation: portrait) {
    .merinLogo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        background-color: transparent;
        box-shadow: none;
    }
    .merinLogoImgContainer {
        display: none; /* display: flex; tuo kuvan takaisin */
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        width: 50%;
    }
    .merinLogoImg {
        height: 16vmin;
        width: 16vmin;
        padding: 2px;
        background-color: #fdf4da;
        border-radius: 4px;
    }
    .merinLogoText {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 50%;
        height: 30%;
        margin-right: 5px;
    }
    .logoTextNimi {
        width: 100%;
        height: 100%;
        font-size: calc(14px + 1.5vmin);
        text-decoration: none;
        font-weight: 300;
    }
    .logoTextEmail {
        width: 100%;
        height: 100%;
        font-size: calc(7px + 1.5vmin);
        text-decoration: none;
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 16px;
        width: 70%;
        z-index: auto;
    }
    .spacer {
        height: 1.2px;
        width: 85%;
        background-color: rgb(181, 189, 180);
        margin-bottom: 5px;
        animation: fadeIn 1s ease-out forwards;
    }
}
.comissionedText {
    position: relative;
    left: 6vh;
    top: 5vh;
}
.comissionedTextStyle {
    font-size: calc(4px + 1vmin);
    font-weight: bold;
}
@media screen and (max-width: 1024px) {
    .comissionedText {
        visibility: hidden;
    }
}

/* HAMBURGER MENU ON DESKTOP */

.hamburger {
    position: absolute;
    top: 1%;
    left: 5px;
    z-index: 3;
}
.hamburger-icon {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
}
.hamburger-icon div {
    width: 1.7rem;
    height: 2px;
    margin: 3px;
    background-color: grey;
    animation: fadeIn 1s ease-out forwards;
}
/* HAMBURGER MENU ON MOBILE */

@media screen and (max-width: 1024px) and (orientation: portrait) {
    .hamburger {
        position: absolute;
        top: 2.5%;
        right: 10%;
        left: unset;
    }
}

/* MENU MODAL */
@keyframes slideDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes slideUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(-100%);
        opacity: 0;
    }
}
.menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 199;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1); /* (0, 0, 0, 0.85); */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}
.menu-modal.slide-down {
    animation: slideDown 0.5s ease-out forwards;
}

.menu-modal.slide-up {
    animation: slideUp 0.5s ease-out forwards;
}

.menuLinks {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.menuLinks h3 {
    margin-bottom: 8px;
    text-decoration: underline;
}
.menuLinks h4 {
    text-decoration: underline;
    cursor: pointer;
}

.menuLinks p {
    margin: 0.25rem;
    cursor: pointer;
}

.menuLinks p:hover {
    color: black;
}
.menuLinks h4:hover {
    color: rgb(99, 99, 99);
}

/* MENU ANIMATIONS */
@keyframes staggeredText {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.menuLinks > * {
    opacity: 0;
    transform: translateY(-100%);
}

.menuLinks *:nth-child(1) {
    animation: staggeredText 0.5s ease-out forwards;
}

.menuLinks *:nth-child(2) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 100ms;
}

.menuLinks *:nth-child(3) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 200ms;
}

.menuLinks *:nth-child(4) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 300ms;
}
.menuLinks *:nth-child(5) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 400ms;
}
.menuLinks *:nth-child(6) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 500ms;
}
.menuLinks *:nth-child(7) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 600ms;
}
.menuLinks *:nth-child(8) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 700ms;
}
.menuLinks *:nth-child(9) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 800ms;
}
.menuLinks *:nth-child(10) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 900ms;
}
.menuLinks *:nth-child(11) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 1000ms;
}
.menuLinks *:nth-child(12) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 1100ms;
}
.menuLinks *:nth-child(13) {
    animation: staggeredText 0.5s ease-out forwards;
    animation-delay: 1200ms;
}

/* FRONT PAGE IMAGE ANIMATIONS */

.topSide > *,
.mid > *,
.botSide > * {
    opacity: 0;
}

@keyframes staggering {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* TOP */
.topSide > *:nth-child(1) {
    animation: staggering 0.75s ease-out forwards 100ms;
}
.topSide > *:nth-child(2) {
    animation: staggering 0.75s ease-out forwards 100ms;
}
.topSide > *:nth-child(3) {
    animation: staggering 0.75s ease-out forwards 200ms;
}
.topSide > *:nth-child(4) {
    animation: staggering 0.75s ease-out forwards 200ms;
}
.topSide > *:nth-child(5) {
    animation: staggering 0.75s ease-out forwards 300ms;
}
.topSide > *:nth-child(6) {
    animation: staggering 0.75s ease-out forwards 300ms;
}
.topSide > *:nth-child(7) {
    animation: staggering 0.75s ease-out forwards 400ms;
}
.topSide > *:nth-child(8) {
    animation: staggering 0.75s ease-out forwards 400ms;
}
/* MID */
.mid > *:nth-child(1) {
    animation: staggering 0.75s ease-out forwards 500ms;
}
.mid > *:nth-child(2) {
    animation: staggering 0.75s ease-out forwards 500ms;
}
.mid > *:nth-child(3) {
    animation: staggering 0.75s ease-out forwards 600ms;
}
.mid > *:nth-child(4) {
    animation: staggering 0.75s ease-out forwards 600ms;
}
.mid > *:nth-child(5) {
    animation: staggering 0.75s ease-out forwards 700ms;
}
.mid > *:nth-child(6) {
    animation: staggering 0.75s ease-out forwards 700ms;
}
.mid > *:nth-child(7) {
    animation: staggering 0.75s ease-out forwards 800ms;
}
.mid > *:nth-child(8) {
    animation: staggering 0.75s ease-out forwards 800ms;
}
/* BOT */
.botSide > *:nth-child(1) {
    animation: staggering 0.75s ease-out forwards 900ms;
}
.botSide > *:nth-child(2) {
    animation: staggering 0.75s ease-out forwards 900ms;
}
.botSide > *:nth-child(3) {
    animation: staggering 0.75s ease-out forwards 1000ms;
}
.botSide > *:nth-child(4) {
    animation: staggering 0.75s ease-out forwards 1000ms;
}
.botSide > *:nth-child(5) {
    animation: staggering 0.75s ease-out forwards 1100ms;
}
.botSide > *:nth-child(6) {
    animation: staggering 0.75s ease-out forwards 1100ms;
}
.botSide > *:nth-child(7) {
    animation: staggering 0.75s ease-out forwards 1100ms;
}
.botSide > *:nth-child(8) {
    animation: staggering 0.75s ease-out forwards 1100ms;
}
/* MODAL IMAGE ANIMATIONS */

@keyframes slideImage {
    from {
        transform: translateY(100%);
        filter: blur(5px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        filter: blur(0);
        opacity: 1;
    }
}

.imageShow {
    opacity: 0;
    transform: translateY(100%);
    animation: slideImage 1s ease-out forwards;
    cursor: pointer;
}

/* YOUTUBE VIDEO MODAL */

.youtube-video-paskuli {
    width: 80%;
    height: 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.youtube-video-container {
    scroll-snap-type: x mandatory;
    width: 60%;
    height: 60%;
}

.prev-button {
    background-image: url('./images/logo/arrow.png');
    transform: rotate(180deg);
}

.next-button {
    background-image: url('./images/logo/arrow.png');
}

.navigation-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 30px; /* Adjust as necessary for your image's aspect ratio */
    height: 30px; /* Adjust as necessary for your image's aspect ratio */
    background-size: cover; /* This will ensure your image covers the button area */
    background-repeat: no-repeat;
    background-position: center;
}

@media screen and (max-width: 1440px) {
    .youtube-video-container {
        width: 80%;
    }
}
@media screen and (max-width: 1024px) {
    .youtube-video-container {
        width: 100%;
    }
    .carousel-indicator {
        height: 7px;
        width: 7px;
        bottom: 5px;
    }

}

.youtube-video {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
}

.controls {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-top: 5%;
    width: 60%;
}

.navigation-button {
    position: relative;
    background-color: transparent;
    color: var(--color-text);
    border: none;
    cursor: pointer;
    font-size: 20px;
    padding: auto;
    z-index: 2;
}

.prev-button {
    left: 10%;
}

.next-button {
    right: 10%;
}

.carousel-indicators {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.carousel-indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
}
.carousel-indicator.active {
    background-color: var(--color-headings); /* Active indicator color */
}
.kuvaContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.kuvaContainer img {
    height: 90%;
}

.textContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 70%;
    flex-direction: column;
    cursor: pointer;
}
.textContainer h2 {
    margin-bottom: 2%;
}
.menu-modal2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 199;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1); /* (0, 0, 0, 0.85); */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.menu-modal2.slide-down {
    animation: slideDown 0.5s ease-out forwards;
}

.menu-modal2.slide-up {
    animation: slideUp 0.5s ease-out forwards;
}
